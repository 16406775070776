<template>
    <v-container>
        <h3>Tajimingo</h3>
        <v-col class="pr-2 pb-2" cols="12">
            <form @submit.prevent="submitTajimiInfo">
                <v-row>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-operation-manager") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.pic"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-address-tajimiInfo") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.address"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-phone-info") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.phone"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-email-address") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.email"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>URL</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.url"
                        outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-necessary-price") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.price"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-order") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.order"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-payment-method") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.payment_method"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-date-payment") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.payment_date"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-delivery_time") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.delivery_time"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xs="2" class="label-class">
                        <span>{{ $t("message.label-return-exchanges") }}</span>
                    </v-col>
                    <v-col cols="12" md="10" xs="2" class="input-class">
                        <v-text-field
                        v-model="formInfo.return_and_exchange"
                        outlined
                        
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="d-flex flex-row-reverse mt-3 me-0" tile>
                    <div class="m-2">
                        <v-btn type="submit" color="primary" >
                        {{ $t("message.btn-submit") }}
                        </v-btn>
                    </div>
                    <div>
                        <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                        {{ $t("message.btn-back") }}
                        </v-btn>
                    </div>
                </div>
            </form>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "CreateTajimiInfo",
    data() {
        return {
            formInfo: {
                pic: "",
                address: "",
                phone: "",
                email: "",
                url: "",
                price: "",
                order: "",
                payment_method: "",
                payment_date: "",
                delivery_time: "",
                return_and_exchange: ""
            }
        }
    },
    computed: {

    },
    methods: {
        async dispatchPlatformInfo() {
            await this.$store.dispatch("about_module/fetchPlatformInfo")

            let platformInfo = this.$store.state.about_module.resultPlatformInfo
            this.formInfo.pic = platformInfo.ops_manager
            this.formInfo.address = platformInfo.location
            this.formInfo.phone = platformInfo.phone
            this.formInfo.url = platformInfo.url
            this.formInfo.email = platformInfo.email
            this.formInfo.order = platformInfo.how_to_order
            this.formInfo.price = platformInfo.necessary_price
            this.formInfo.payment_method = platformInfo.payment_method
            this.formInfo.payment_date = platformInfo.due_date_payment
            this.formInfo.delivery_time = platformInfo.delivery_time
            this.formInfo.return_and_exchange = platformInfo.about_goods
        },
        submitTajimiInfo() {
            this.$store.dispatch("about_module/createTajimiInfo", this.formInfo)
            this.dispatchPlatformInfo()
        }
    },
    created() {
        this.dispatchPlatformInfo()
    }
}
</script>

<style>

</style>